const acolumns = [
    {
      title: 'No',
      slots: { customRender: 'no' },
    },
    {
      title: 'Provinsi ID',
      dataIndex: 'parent_id',
    },
    {
      title: 'Provinsi Name',
      dataIndex: 'provinsi',
    },
    {
      title: 'Kabupaten ID',
      dataIndex: 'id',
    },
    {
      title: 'Kabupaten Name',
      dataIndex: 'name',
    },
    {
      title: 'Kecamatan ID',
      dataIndex: 'kecamatan_id',
    },
    {
      title: 'Kecamatan Name',
      dataIndex: 'kecamatan',
    },
    {
      title: 'Area ID',
      dataIndex: 'area_id',
    },
    {
      title: 'Area Name',
      dataIndex: 'area',
    },
    {
      title: 'Region',
      dataIndex: 'region',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      slots: { customRender: 'action' },
    },
  ]
  export default acolumns
  